<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="button" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-thead" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tbody" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tfoot" min-width="100px"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" md="12">
        <h1 class="titulo">Controle de bancos</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="9">
        <v-btn class="btn-primary ml-0" text @click="addBank">
          <i class="far fa-plus" ></i>
          Adicionar</v-btn>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
          hide-details></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="card-cambio">
          <v-data-table :headers="columns" :items="banks" :search="search" sort-by="id" sort-desc :items-per-page="10">
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon text color="primary" @click="editBank(item)" v-bind="attrs" v-on="on">
                    <i class="far fa-pencil" ></i>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>
<script>
// Apis
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";

// Models
import ExchangeBankModel from "@/model/exchange-bank-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Utils
import utilsStorage from "@/utils/storage";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "ExchangeBankControl",
  mixins: [mixinMessage],
  data() {
    return {
      loading: true,
      search: "",
      user: utilsStorage.getUserOnStorage(),
      banks: [],
      columns: [
        { text: "Código", value: "id", align: "left", sortable: true, width: "100px" },
        { text: "Razão Social", value: "companyName", align: "left", sortable: true },
        { text: "Nome Fantasia", value: "fantasyName", align: "left", sortable: true },
        { text: "CNPJ", value: "cnpj", align: "left", sortable: true },
        { text: "Email", value: "firstRegisterEmail", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  mounted() {
    this.getBankList();
    mixpanel.track("page_view", {
      name_of_page_viewed: "banks_control",
      user_id: this.user.id,
    });
  },

  methods: {
    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    addBank() {
      this.$router.push({
        name: "FormCreateBank",
      });
    },

    editBank(bank) {
      mixpanel.track("click", {
        button_name: "edit_bank",
      });
      this.$router
        .push({
          name: "FormEditBank",
          params: {
            id: bank.id,
          },
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped></style>
