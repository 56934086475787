<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Spreads</h1>
        <v-divider class="py-3 mt-3"></v-divider>
        <v-row>
          <v-col cols="12" md="10">
            <v-btn class="btn-primary ml-0" text @click="openAddSpreadModal">
              <i class="far fa-plus" ></i>
              Adicionar</v-btn>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
              hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-card class="card-cambio">
              <v-data-table :headers="columns" :items="store.exchangeSpreads" :search="search" sort-by="id" sort-desc
                :items-per-page="10">
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon text @click="editSpreadModal(item)" v-bind="attrs" v-on="on">
                        <i class="far fa-pencil" ></i>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Spread Modal -->
    <v-dialog persistent scrollable eager min-width="40vw" max-width="100vh" v-model="addSpreadModal">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Spreads</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form id="add-spread" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <v-autocomplete label="Moeda" :items="notUsedCurrencies" item-text="name" item-value="id"
                      :disabled="update" v-model="spread.currency" return-object clearable dense outlined
                      v-disabled-icon-focus>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-card elevation="0">
                      <v-card-title>
                        <h1 class="titulo-modal">Venda</h1>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="py-5">
                        <v-row>
                          <v-col class="py-0" cols="12" md="6">
                            <NumberField v-model="spread.cost" outlined dense label="Custo da ME Venda(%)" suffix="%">
                            </NumberField>
                          </v-col>
                          <v-col class="py-0" cols="12" md="6">
                            <MoneyField label="Valor Para Isenção de Delivery" prefix="$ "
                              v-model="spread.deliveryCostExemptionAmount" />
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <NumberField v-model="spread.minimumProfit" outlined dense label="Lucro Mínimo Venda(%)"
                              suffix="%"></NumberField>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <NumberField v-model="spread.recommendedProfit" outlined dense
                              label="Lucro Recomendado Venda(%)" suffix="%"></NumberField>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <NumberField v-model="spread.maximumProfit" outlined dense label="Lucro Máximo Venda(%)"
                              suffix="%"></NumberField>
                          </v-col>

                          <v-col class="py-0" cols="12" md="4">
                            <legend>Spread Mínimo Venda(%)</legend>
                            <h3>{{ spread.minimumSpread }}</h3>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <legend>Spread Recomendado Venda(%)</legend>
                            <h3>{{ spread.recommendedSpread }}</h3>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <legend>Spread Máximo Venda(%)</legend>
                            <h3>{{ spread.maximumSpread }}</h3>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-card elevation="0">
                      <v-card-title>
                        <h1 class="titulo-modal">Compra</h1>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="py-5">
                        <v-row>
                          <v-col class="py-0" cols="12" md="12">
                            <NumberField v-model="spread.costBuy" outlined dense label="Custo da ME Compra(%)" suffix="%">
                            </NumberField>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <NumberField v-model="spread.minimumProfitBuy" outlined dense label="Lucro Mínimo Compra(%)"
                              suffix="%"></NumberField>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <NumberField v-model="spread.recommendedProfitBuy" outlined dense
                              label="Lucro Recomendado Compra(%)" suffix="%"></NumberField>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <NumberField v-model="spread.maximumProfitBuy" outlined dense label="Lucro Máximo Compra(%)"
                              suffix="%"></NumberField>
                          </v-col>

                          <v-col class="py-0" cols="12" md="4">
                            <legend>Spread Mínimo Compra(%)</legend>
                            <h3>{{ spread.minimumSpreadBuy }}</h3>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <legend>Spread Recomendado Compra(%)</legend>
                            <h3>{{ spread.recommendedSpreadBuy }}</h3>
                          </v-col>
                          <v-col class="py-0" cols="12" md="4">
                            <legend>Spread Máximo Compra(%)</legend>
                            <h3>{{ spread.maximumSpreadBuy }}</h3>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn v-if="!update" text class="btn-primary mr-2" @click="addSpread">
              Salvar
            </v-btn>
            <v-btn v-else-if="update" text class="btn-primary mr-2" @click="updateSpread">
              Salvar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="addSpreadModal = false">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiCurrency from "@/api/spread/currency-api";
import apiSpread from "@/api/spread/spread-api";

// Components
import NumberField from "@/components/comum/NumberField";
import MoneyField from "@/components/comum/MoneyField.vue";

// Models
import SpreadModel from "@/model/spread-model";
import CurrencyModel from "@/model/currency-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "Spreads",
  mixins: [mixinMessage],
  components: {
    NumberField,
    MoneyField,
  },
  model: {
    prop: "storeProp",
    event: "onChange",
  },
  props: {
    storeProp: {
      type: Object,
    },
  },
  data() {
    return {
      store: this.storeProp,
      search: "",
      currencies: [],
      columns: [
        { text: "Código", value: "currency.code", align: "left", sortable: true },
        { text: "Nome", value: "currency.name", align: "left", sortable: true },
        { text: "Spread Mínimo Venda(%)", value: "minimumSpread", align: "right", sortable: true },
        { text: "Spread Recomendado Venda(%)", value: "recommendedSpread", align: "right", sortable: true },
        { text: "Spread Máximo Venda(%)", value: "maximumSpread", align: "right", sortable: true },
        { text: "Valor Para Isenção de Delivery($)", value: "deliveryCostExemptionAmount", align: "right", sortable: true },
        { text: "Spread Mínimo Compra(%)", value: "minimumSpreadBuy", align: "right", sortable: true },
        { text: "Spread Recomendado Compra(%)", value: "recommendedSpreadBuy", align: "right", sortable: true },
        { text: "Spread Máximo Compra(%)", value: "maximumSpreadBuy", align: "right", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      spread: new SpreadModel(),
      addSpreadModal: false,
      update: false,
    };
  },
  watch: {
    "spread.cost"() {
      this.spread.minimumSpread = this.spread.cost + this.spread.minimumProfit;
      this.spread.recommendedSpread = this.spread.cost + this.spread.recommendedProfit;
      this.spread.maximumSpread = this.spread.cost + this.spread.maximumProfit;
    },
    "spread.minimumProfit"() {
      this.spread.minimumSpread = this.spread.cost + this.spread.minimumProfit;
    },
    "spread.recommendedProfit"() {
      this.spread.recommendedSpread = this.spread.cost + this.spread.recommendedProfit;
    },
    "spread.maximumProfit"() {
      this.spread.maximumSpread = this.spread.cost + this.spread.maximumProfit;
    },
    "spread.costBuy"() {
      this.spread.minimumSpreadBuy = this.spread.costBuy + this.spread.minimumProfitBuy;
      this.spread.recommendedSpreadBuy = this.spread.costBuy + this.spread.recommendedProfitBuy;
      this.spread.maximumSpreadBuy = this.spread.costBuy + this.spread.maximumProfitBuy;
    },
    "spread.minimumProfitBuy"() {
      this.spread.minimumSpreadBuy = this.spread.costBuy + this.spread.minimumProfitBuy;
    },
    "spread.recommendedProfitBuy"() {
      this.spread.recommendedSpreadBuy = this.spread.costBuy + this.spread.recommendedProfitBuy;
    },
    "spread.maximumProfitBuy"() {
      this.spread.maximumSpreadBuy = this.spread.costBuy + this.spread.maximumProfitBuy;
    },
  },
  mounted() {
    this.getCurrencies();
  },
  computed: {
    notUsedCurrencies() {
      let filtered = this.currencies.filter(currency => {
        return this.store.exchangeSpreads.filter(spread => {
          return spread.currency.id == currency.id;
        }).length == 0
      });

      if (this.spread?.currency?.id) {
        filtered.push(this.spread.currency)
      }

      return filtered;
    },
  },
  methods: {
    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      apiCurrency.findAll()
        .then((response) => {
          this.currencies = response.data.map(
            (c) => new CurrencyModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
    },


    openAddSpreadModal() {
      this.update = false;
      this.spread = new SpreadModel();
      this.addSpreadModal = true;
    },

    editSpreadModal(spread) {
      this.update = true;
      this.spread = Object.assign({}, spread);
      this.addSpreadModal = true;
    },

    addSpread() {
      if (!this.store.id) {
        this.store.exchangeSpreads.push(new SpreadModel(this.spread));
        this.addSpreadModal = false;
        return;
      }

      apiSpread
        .add(this.spread, this.store.id)
        .then((response) => {
          this.store.exchangeSpreads.push(new SpreadModel(response.data));
          this.addSpreadModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
    },

    updateSpread() {
      let storeId = parseInt(this.store.id)
      apiSpread
        .update(this.spread, storeId)
        .then((response) => {
          let index = this.store.exchangeSpreads.findIndex((s) => s.id == this.spread.id);
          this.store.exchangeSpreads.splice(
            index,
            1,
            new SpreadModel(response.data)
          );
          this.addSpreadModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
    },
  }
};
</script>

<style></style>
