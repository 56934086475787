export default class OperationFiltersModel {
  constructor(obj) {
    obj = obj || {};

    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.statusId = obj.statusId;
    this.exchangeTypeId = obj.exchangeTypeId;
    this.exchangeBuy = obj.exchangeBuy;
    this.currencyId = obj.currencyId;
    this.bankId = obj.bankId;
    this.agentId = obj.agentId;
    this.idPersonType = obj.idPersonType;
    this.cpfCnpj = obj.cpfCnpj;
  }
}