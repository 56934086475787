<template>
  <v-row >
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Contas de Pagamento</h1>
        <v-divider class="py-3"></v-divider>
        <v-row v-for="(bank, index) in customer.paymentAccounts" :key="index">
          <v-col class="py-0" cols="12" md="4">
            <v-autocomplete
              label="Banco"
              :item-text="itemTextBank"
              clearable
              item-value="code"
              dense
              outlined
              v-disabled-icon-focus
              v-model="bank.bank"
              :items="paymentBankList"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <v-text-field
              v-model="bank.agency"
              outlined
              dense
              label="Agência"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <v-text-field
              v-model="bank.account"
              outlined
              dense
              label="Conta"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="1">
            <v-text-field
              v-model="bank.digit"
              outlined
              dense
              label="Digito"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="1" v-if="customer.paymentAccounts.length >= 2" class="py-0">
            <v-btn icon class="float-right" @click="removeAccount(index)" text>
              <i class="far fa-trash"></i>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-0">
          <v-col class="pt-5">
            <v-btn class="btn-secondary float-right" @click="insertAccount" text>
              <i class="far fa-plus" ></i> Adicionar outro
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Models
import CustomerPaymentAccountModel from "@/model/customer-payment-account-model";
import ExchangeBankModel from "@/model/exchange-bank-model";

// APIs
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiBanks from "@/api/general/bank-api";

export default {
  name: "CustomerPaymentAccounts",
  components: {  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  data() {
    return {
      customer: this.customerProp,
      exchangeBanks: [],
      listOfBanks: [],
    };
  },
  mounted() {
    this.getExchangeBanks();
    this.getBankList();
    if (this.customer.paymentAccounts.length == 0) {
      this.customer.paymentAccounts = [new CustomerPaymentAccountModel()];
    }
  },
  computed: {
    paymentBankList(){
      let paymentBankCodes = this.exchangeBanks.filter(b => b.paymentAccount && b.linkedBank).map(b=> parseInt(b.linkedBank));
      let paymentBanks = this.listOfBanks.filter(b => paymentBankCodes.includes(b.code));
      return paymentBanks;
    },
    itemTextBank() {
      return (item) => !item.code ? '000 - ' + item.name.toUpperCase() : item.code + ' - ' + item.name.toUpperCase();
    }
  },
  methods: {
    getExchangeBanks() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.exchangeBanks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getBankList() {
      apiBanks
        .getBanks()
        .then((response) => {
          this.listOfBanks = response.data.reduce((accumulator, current) => {
            if (current.name == undefined || !current.code) {
              return accumulator;
            }
            accumulator.push(current);
            return accumulator;
          }, []);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    insertAccount() {
      this.customer.paymentAccounts.push(new CustomerPaymentAccountModel());
    },
    removeAccount(index) {
      if (this.customer.paymentAccounts.length > 1) {
        this.customer.paymentAccounts.splice(index, 1);
      }
    },
  },
};
</script>

<style>
</style>