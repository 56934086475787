import api from '../api';

function add(operation) {
  operation = operation || {};
  return new Promise((resolve, reject) => {
    return api.post('/operations', operation)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function find(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/operations/', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(operation) {
  operation = operation || {};
  return new Promise((resolve, reject) => {
    return api.put(`/operations/${operation.id}`, operation)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};
  return new Promise((resolve, reject) => {
    return api.get(`/operations/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function download(documentId) {
  return new Promise((resolve, reject) => {
    return api.get('/operation/documents/' + documentId + '/download', { responseType: 'blob' })
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

export default {
  add,
  find,
  update,
  findId,
  download,
};