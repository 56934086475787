<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="button" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-thead" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tbody" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tfoot" min-width="100px"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>

      <v-col cols="12" md="12">
        <h1 class="titulo">Autorização de fichas</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="3" offset="9">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
          hide-details></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="card-cambio">
          <v-data-table :headers="columns" :items="customers" :search="search" sort-by="id" sort-desc
            :items-per-page="10">
            <template v-slot:[`item.updatedAt`]="{ item }">
              {{ item.updatedAt | date }}
            </template>
            <template v-slot:[`item.registerStatus.description`]="{ item }">
              <v-chip small label :color="item.registerStatus.id | statusColor">
                {{ item.registerStatus.description }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon text color="primary" @click="authorizeCustomer(item)" v-bind="attrs" v-on="on">
                    <i class="far fa-eye"></i>
                  </v-btn>
                </template>
                <span>Visualizar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>
<script>
// Apis
import apiCustomers from "@/api/customer/customer-api";

// Models
import CustomerModel from "@/model/customer-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Utils
import dateUtils from "@/utils/date";
import utilsStorage from "@/utils/storage";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "CustomersAuthorizationControl",
  mixins: [mixinMessage],
  components: {},
  filters: {
    date(value) {
      return dateUtils.maskDateAndHourWithoutSeconds(value);
    },
    statusColor(statusId) {
      if (statusId == 2) {
        return 'red darken-1';
      } else if (statusId == 6) {
        return 'green lighten-1';
      } else {
        return 'yellow darken-3';
      }
    }
  },
  data() {
    return {
      loading: true,
      search: "",
      user: utilsStorage.getUserOnStorage(),
      customers: [],
      columns: [
        { text: "Código", value: "id", align: "left", sortable: true, },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "Email", value: "email", align: "left", sortable: true },
        { text: "Última Interação", value: "updatedAt", align: "center", sortable: true, },
        { text: "Status", value: "registerStatus.description", align: "center", },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  mounted() {
    this.getCustomersList();
    mixpanel.track("page_view", {
      name_of_page_viewed: "customers_authorization_control",
      user_id: this.user.id,
    });
  },

  computed: {

  },

  methods: {
    getCustomersList() {
      apiCustomers
        .findAll()
        .then((response) => {
          this.customers = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    authorizeCustomer(customer) {
      mixpanel.track("click", {
        button_name: "authorize_customer",
      });
      this.$router
        .push({
          name: "FormAuthorizationCustomer",
          params: {
            id: customer.id,
          },
        })
        .catch(() => { });
    },

  },
};
</script>

<style scoped></style>
