<template>
  <v-row>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">Cotação</legend>
      <p class="operation-value">{{ operation.currencyQuotation || 0 }}</p>
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">IOF(%)</legend>
      <p class="operation-value">{{ operation.iof || 0 }}</p>
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">IOF</legend>
      <p class="operation-value">{{ operation.iofValue || 0 }}</p>
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">IR(%)</legend>
      <p class="operation-value">{{ operation.ir || 0 }}</p>
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">IR</legend>
      <p class="operation-value">{{ operation.irValue || 0 }}</p>
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">Taxa do Cliente</legend>
      <p class="operation-value">{{ operation.customerRate || 0 }}</p>
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">VET</legend>
      <p class="operation-value">{{ operation.vet || 0 }}</p>
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend class="operation-title">Total da Operação</legend>
      <p class="operation-value">{{ operation.totalValue || 0 }}</p>
    </v-col>
  </v-row>
</template>

<script>
// Utils
import operationUtils from "@/utils/operation";

export default {
  name: "OperationPreviewExchange",
  mixins: [  ],
  components: {  },
  data() {
    return {
      operation: this.operationProp,
    };
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  watch: {
    operation() {
      this.$emit("onChange", this.operation);
    },
    "operation.nature"() {
      this.updateValues();
    },
    "operation.amount"() {
      this.updateValues();
    },
    "operation.currency"() {
      this.operation.currencyQuotation = 4.7789
      operationUtils.resetSpreads(this.operation);
      this.updateValues();
    },
    "operation.exchangeBuy"() {
      this.updateValues();
    },
    "operation.bank"() {
      this.updateValues();
    },
    "operation.spread"() {
      this.updateValues();
    },
    "operation.customerRate"() {
      operationUtils.getSpreadFromCustomerRateRemittance(this.operation);
      operationUtils.calculateOperationRemittance(this.operation);
    },
    "operation.exchangeContractCost"() {
      this.updateValues();
    },
  },
  mounted() {
  },
  computed: {
    
  },
  methods: {
    updateValues(){
      operationUtils.setBaseValuesOperationRemittance(this.operation);
      operationUtils.calculateOperationRemittance(this.operation);
    }


  }
};
</script>

<style lang="scss" scoped>
.operation-title {
  font-size: 0.9rem;
}
p.operation-value{
  font-size: 1.3rem;
  color: $primary_color;
}
</style>
