<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="12">
      <div class="box">
        <h1 class="titulo">Naturezas de Operação</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col cols="12" md="9">
            <v-btn class="btn-primary ml-0" text @click="openAddNatureModal">
              <i class="far fa-plus" ></i> Adicionar
            </v-btn>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
              hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-card class="card-cambio">
              <v-data-table :headers="columns" :items="natures" :search="search" sort-by="id" sort-asc
                :items-per-page="10" :custom-filter="caseInsensitiveAccentsInsensitive">
                <template v-slot:[`item.useDiDue`]="{ item }">
                  <template v-if="item.useDiDue">
                    <i class="far fa-check-circle fa-lg"></i>
                  </template>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon text color="primary" @click="editNatureModal(item)" v-bind="attrs" v-on="on">
                        <i class="far fa-pencil" ></i>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add nature Modal -->
    <v-dialog persistent eager v-model="addNatureModal" max-width="500px">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Naturezas de Operação</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col cols="12" md="12">
              <v-form id="add-nature" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <v-text-field outlined dense label="Nome" v-model="nature.name"></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="12">
                    <NumberField v-model="nature.iof" outlined dense label="IOF Venda(%)" suffix="%" :precisaoDecimal="6">
                    </NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="12">
                    <NumberField v-model="nature.ir" outlined dense label="IR Venda(%)" suffix="%" :precisaoDecimal="6">
                    </NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="12">
                    <NumberField v-model="nature.iofBuy" outlined dense label="IOF Compra(%)" suffix="%"
                      :precisaoDecimal="6"></NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="12">
                    <NumberField v-model="nature.irBuy" outlined dense label="IR Compra(%)" suffix="%"
                      :precisaoDecimal="6"></NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="2">
                    <legend>Usa DI/DUE?</legend>
                    <v-radio-group row v-model="nature.useDiDue" class="py-0" dense>
                      <v-radio :value="true" label="SIM"></v-radio>
                      <v-radio :value="false" label="NÃO"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn v-if="!update" text class="btn-primary mr-2" @click="addNature">
              Salvar
            </v-btn>
            <v-btn v-else-if="update" text class="btn-primary mr-2" @click="updateNature">
              Salvar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="addNatureModal = false">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiOperationNature from "@/api/configuration/operation-nature-api";

// Components
import NumberField from "@/components/comum/NumberField";

// Models
import OperationNatureModel from "@/model/operation-nature-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

export default {
  name: "OperationNaturesControl",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: {
    NumberField,
  },
  data() {
    return {
      search: "",
      natures: [],
      columns: [
        { text: "Código", value: "id", align: "left", sortable: true, width: "100px" },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "IOF Venda(%)", value: "iof", align: "right", sortable: true },
        { text: "IR Venda(%)", value: "ir", align: "right", sortable: true },
        { text: "IOF Compra(%)", value: "iofBuy", align: "right", sortable: true },
        { text: "IR Compra(%)", value: "irBuy", align: "right", sortable: true },
        { text: "D.I./D.U.E.", value: "useDiDue", align: "center", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      nature: new OperationNatureModel(),
      addNatureModal: false,
      update: false,
    };
  },
  watch: {},
  mounted() {
    this.getNatureList();
  },
  computed: {},
  methods: {
    getNatureList() {
      apiOperationNature
        .findAll()
        .then((response) => {
          this.natures = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddNatureModal() {
      this.update = false;
      this.nature = new OperationNatureModel();
      this.addNatureModal = true;
    },

    editNatureModal(nature) {
      this.update = true;
      this.nature = new OperationNatureModel(nature);
      this.addNatureModal = true;
    },

    addNature() {
      apiOperationNature
        .add(this.nature)
        .then((response) => {
          this.natures.push(new OperationNatureModel(response.data));
          this.addNatureModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateNature() {
      apiOperationNature
        .update(this.nature)
        .then((response) => {
          let index = this.natures.findIndex((s) => s.id == this.nature.id);
          this.natures.splice(index, 1, new OperationNatureModel(response.data));
          this.addNatureModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
