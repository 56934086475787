<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="12">
      <div class="box">
        <h1 class="titulo">Operações de Remessa</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col cols="12" md="12" >
            <v-form id="filters-form">
              <v-row>
                <v-col cols="12" lg="3">
                  <Datepicker 
                    v-model="filters.startDate"
                    label="Data de Início"
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col cols="12" lg="3">
                  <Datepicker 
                    v-model="filters.endDate"
                    label="Data de Fim"
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col cols="12" lg="3">
                  <v-autocomplete
                    label="Tipo"
                    :items="listExchangeType"
                    item-text="label"
                    v-model="filters.exchangeBuy"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-autocomplete
                    label="Moeda"
                    :items="currencyList"
                    item-text="name"
                    item-value="id"
                    v-model="filters.currencyId"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-autocomplete
                    label="Banco"
                    :items="bankList"
                    item-text="fantasyName"
                    item-value="id"
                    v-model="filters.bankId"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-autocomplete
                    label="Agente"
                    :items="agentList"
                    item-text="name"
                    item-value="id"
                    v-model="filters.agentId"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-autocomplete
                    label="Tipo de Pessoa"
                    :items="personTypeList"
                    item-text="description"
                    item-value="id"
                    v-model="filters.idPersonType"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="filters.cpfCnpj"
                    label="Cpf/Cnpj"
                    v-mask="filters.cpfCnpj?.length > 14 ? '##.###.###/####-##' : '###.###.###-##'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" offset="9">
                  <v-btn class="btn-primary float-right" text @click="getOperations">
                    <i class="far fa-search" ></i>
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" md="3" offset="9">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="!operations.length" cols="12" md="12">
            <EmptyTable title="Nenhum registro encontrado" icon="far fa-hand-holding-usd" subtitle="Não há operações de Câmbio Remessa para o período selecionado." />
          </v-col>
          <v-col v-else cols="12" md="12">
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="operations"
                :search="search"
                sort-by="updatedAt"
                sort-desc
                :items-per-page="10"
                :custom-filter="caseInsensitiveAccentsInsensitive"
              >

                <template v-slot:[`item.updatedAt`]="{ item }">
                  <span>{{ item.updatedAt | dateTime }}</span>
                </template>

                <template v-slot:[`item.exchangeBuy`]="{ item }">
                  <span v-if="item.exchangeBuy"> Compra </span>
                  <span v-else> Venda </span>
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  <span>{{ item.amount | formatCurrency(item.currency.code) }}</span>
                </template>

                <template v-slot:[`item.bankRate`]="{ item }">
                  <span>{{ item.bankRate | formatCurrency('BRL', 6) }}</span>
                </template>

                <template v-slot:[`item.customerRate`]="{ item }">
                  <span>{{ item.customerRate | formatCurrency('BRL', 6) }}</span>
                </template>

                <template v-slot:[`item.iofValue`]="{ item }">
                  <span>{{ item.iofValue | formatCurrency('BRL') }}</span>
                </template>

                <template v-slot:[`item.irValue`]="{ item }">
                  <span>{{ item.irValue | formatCurrency('BRL') }}</span>
                </template>

                <template v-slot:[`item.exchangeContractCost`]="{ item }">
                  <span>{{ item.exchangeContractCost | formatCurrency('BRL') }}</span>
                </template>

                <template v-slot:[`item.totalValue`]="{ item }">
                  <span>{{ item.totalValue | formatCurrency('BRL') }}</span>
                </template>

                <template v-slot:[`item.cc`]="{ item }">
                  <DataTableDocumentInput
                    downloadFileName="CC"
                    :callbackAddDocument="addDocument"
                    :callbackDownloadDocument="downloadDocument"
                    v-model="item.cc"
                  />
                </template>

                <template v-slot:[`item.swift`]="{ item }">
                  <DataTableDocumentInput
                    downloadFileName="SWIFT"
                    :callbackAddDocument="addDocument"
                    :callbackDownloadDocument="downloadDocument"
                    v-model="item.swift"
                  />
                </template>

                <template v-slot:[`item.didue`]="{ item }">
                  <DataTableDocumentInput
                    downloadFileName="DI/DUE"
                    :callbackAddDocument="addDocument"
                    :callbackDownloadDocument="downloadDocument"
                    v-model="item.didue"
                    v-if="item.nature.useDiDue"
                  />
                </template>

              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Api
import apiOperation from "@/api/operation/operation-api";
import apiCurrency from "@/api/spread/currency-api";
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiCorrespondent from "@/api/correspondent/correspondent-api";
import apiOperationDocuments from "@/api/operation/operation-documents-api";

// Models
import OperationModel from "@/model/operation-model";
import OperationFiltersModel from "@/model/operation-filters-model";
import CurrencyModel from "@/model/currency-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import CorrespondentModel from "@/model/correspondent-model";
import OperationDocumentModel from "@/model/operation-document-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

// Utils
import dateUtils from "@/utils/date";
import moneyUtils from "@/utils/money";

// Components
import Datepicker from "@/components/comum/Datepicker.vue";
import DataTableDocumentInput from "@/components/comum/DataTableDocumentInput.vue";
import EmptyTable from "@/components/comum/EmptyTable.vue";

// Constants
import { PERSON_TYPE, OPERATION_DOCUMENT_TYPES } from "@/constants/general-constants";

export default {
  name: "RemittanceOperationReport",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: { Datepicker, DataTableDocumentInput, EmptyTable },
  data() {
    return {
      search: "",
      operations: [],
      columns: [
        { text: "Data", value: "updatedAt", align: "left", sortable: true },
        { text: "Cpf/Cnpj", value: "customer.cpfCnpj", align: "left", sortable: true },
        { text: "Cliente", value: "customer.name", align: "left", sortable: true },
        { text: "Tipo", value: "exchangeBuy", align: "left", sortable: true },
        { text: "M.E.", value: "currency.name", align: "left", sortable: true },
        { text: "Valor M.E.", value: "amount", align: "left", sortable: true },
        { text: "Taxa do Banco", value: "bankRate", align: "left", sortable: true },
        { text: "Taxa do Cliente", value: "customerRate", align: "left", sortable: true },
        { text: "IOF", value: "iofValue", align: "left", sortable: true },
        { text: "IR", value: "irValue", align: "left", sortable: true },
        { text: "Custo de Contrato de Câmbio", value: "exchangeContractCost", align: "left", sortable: true },
        { text: "Valor Total", value: "totalValue", align: "left", sortable: true },
        { text: "Natureza da Operação", value: "nature.name", align: "left", sortable: true },
        { text: "Agente", value: "agent.name", align: "left", sortable: true },
        { text: "Banco", value: "bank.fantasyName", align: "left", sortable: true },
        { text: "C.C.", value: "cc", align: "center", sortable: true },
        { text: "SWIFT", value: "swift", align: "center", sortable: true },
        { text: "D.I./D.U.E.", value: "didue", align: "center", sortable: true },
      ],
      filters: new OperationFiltersModel(),
      listExchangeType:[ { value: true, label: "Compra" }, { value: false, label: "Venda" } ],
      currencyList: [],
      bankList: [],
      agentList: [],
      personTypeList: PERSON_TYPE,
    };
  },
  watch: {},
  mounted() {
    this.getCurrencies();
    this.getBankList();
    this.getAgentList();
    this.filters = new OperationFiltersModel({
      statusId: 2,
      exchangeTypeId: 1,
      startDate: new Date(),
      endDate: new Date(),
    });
    this.getOperations();
  },
  computed: {},
  filters: {
    dateTime(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
    formatCurrency(value, code, precison) {
      code = code || 'USD';
      return moneyUtils.formatCurrency(code, value, precison);
    },
  },
  methods: {
    getOperations() {
      apiOperation
        .find(this.filters)
        .then((response) => {
          this.operations = response.data.map((o) => {
            let operation = new OperationModel(o)

            operation.cc = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.CC.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.CC });
            operation.swift = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.SWIFT.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.SWIFT });
            operation.didue = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.DIDUE.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.DIDUE });

            return operation;
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage(
              (error.response && error.response.data.mensagem) || error,
              "error"
              );
          }
          this.operations = []
        });
    },
    getCurrencies() {
      if (this.currencyList[0]) {
        return;
      }

      apiCurrency.findAll()
        .then((response) => {
          this.currencyList = response.data.map(
            (c) => new CurrencyModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
    },
    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.bankList = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    getAgentList() {
      apiCorrespondent
        .findAll()
        .then((response) => {
          this.agentList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    addDocument(document) {
      let formData = new FormData();

      formData.append("operationId", document.operationId);
      formData.append("idOperationDocumentType", document.operationDocumentType?.id);
      formData.append("document", document.document);

      return apiOperationDocuments
        .add(formData)
        .then((response) => {
          return document = {
            ...response.data,
          };
        })
        .catch(() => {
          this.sendMessage("Erro ao adicionar o anexo!", "error");
          return document = {
            operationDocumentType: document.operationDocumentType,
          };
        });
    },

    downloadDocument(document) {
      return apiOperationDocuments
        .download(document.id);
    },
  },
};
</script>

<style scoped>


</style>
