<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="box card-cambio">
        <h1>Telefones de contato</h1>
        <v-divider class="py-3"></v-divider>
        <v-row v-for="(item, index) in bank.contacts" :key="index">
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Nome do contato" v-model="item.name"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <v-text-field outlined dense label="Departamento" v-model="item.sector"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <PhoneField isLandline v-model="item.landline" label="Telefone Fixo" />
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <PhoneField v-model="item.cellphone" label="Celular" />
          </v-col>
          <v-col cols="12" md="1" v-if="bank.contacts.length >= 2">
            <v-btn icon class="float-right pb-4" @click="removeContact(index)" text><i class="far fa-trash"></i></v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-0">
          <v-col class="pt-5">
            <v-btn class="btn-secondary float-right" @click="insertContact" text><i class="far fa-plus" ></i> Adicionar
              outro</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Model
import ExchangeBankContactModel from "@/model/exchange-bank-contact-model";

// Components
import PhoneField from "@/components/comum/PhoneField.vue";

export default {
  name: "BankContactNumber",
  components: { PhoneField },
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: {
      type: Object,
    },
  },
  data() {
    return {
      bank: this.bankProp,
    };
  },
  watch: {
    bank() {
      this.$emit("onChange", this.bank);
    },
  },
  methods: {
    insertContact() {
      this.bank.contacts.push(new ExchangeBankContactModel());
    },
    removeContact(index) {
      if (this.bank.contacts.length > 1) {
        this.bank.contacts.splice(index, 1);
      }
    },
  },
  mounted() {
    if (this.bank.contacts.length == 0) {
      this.bank.contacts = [new ExchangeBankContactModel()];
    }
  },
};
</script>

<style></style>