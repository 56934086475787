<template>
  <v-row :id="id" class="py-3">
    <v-col v-if="showInput" class="py-0" cols="12" md="12">
      <v-file-input
        outlined
        dense
        prepend-icon="far fa-file-upload"
        v-model="document.document"
        v-disabled-icon-focus
        hide-input
      >
      </v-file-input>
    </v-col>
    <v-col v-else class="py-0" md="12">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon text @click="downloadDocument()" v-bind="attrs" v-on="on" class="pt-1">
            <i class="far fa-file-download" aria-hidden="true"></i>
          </v-btn>
        </template>
        <span>Baixar</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "DataTableDocumentInput",
  mixins: [ mixinMessage ],
  components: {  },
  model: {
    prop: "input",
    event: "onChange",
  },
  props: {
    id: { type: String, required: false },
    input: { type: Object, required: true },
    downloadFileName: { type: String, required: false, default: 'anexo' },
    callbackAddDocument: { type: Function, required: true },
    callbackDownloadDocument: { type: Function, required: true }, 
  },

  data() {
    return {
      document: { ...this.input },
    };
  },

  watch: {
    "document.document"() {
      if (!this.document.document) {
        this.$emit("onChange", this.document);
        return;
      }
      
      this.callbackAddDocument(this.document)
        .then((added) => {
          this.document = added;
          this.$emit("onChange", this.document);
        })
        .catch(() => {
          this.sendMessage("Erro ao adicionar o anexo!", "error");
        });
      
    },
  },

  methods: {
    downloadDocument() {
      this.callbackDownloadDocument(this.document)
        .then((response) => {
          let blob = new Blob([response.data], { type: response.data.type });
          this.createDownloadLink(blob);

          this.sendMessage("Baixando anexo", "success");
        })
        .catch(() => {
          this.sendMessage("Falha ao baixar o anexo!", "error");
        });
    },

    createDownloadLink(file) {
      let link = document.createElement("a");

      link.href = URL.createObjectURL(file);
      link.setAttribute(
        "download",
        `${this.downloadFileName}`
      );
      link.click();

      URL.revokeObjectURL(link.href);
    },
  },

  computed: {
    showInput() {
      return !(
        (this.document.document || this.document.id) &&
        !(this.document.document && this.document.id)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .fa-file-upload {
  color: $red-500;
}
::v-deep .v-input__prepend-outer{
  align-items: center;
  justify-self: center;
  padding: 0;
  margin: 0 auto;
}
.v-btn__content >i {
  color: $green-500;
}
.v-input__prepend-outer > button::before.v-icon{
  fill: green;
}
</style>