<template>
  <v-list dense nav>
    <v-list-item-group class="pt-15">
      <v-list-item link :to="'/'">
        <v-list-item-action>
          <i class="far fa-chart-pie"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessCustomers()">
        <v-list-item-action>
          <i class="far fa-users"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Clientes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessCorrespondents()">
        <v-list-item-action>
          <i class="far fa-briefcase"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Correspondentes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessBanks()">
        <v-list-item-action>
          <i class="far fa-university"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Bancos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessCustomerAuthorizations()">
        <v-list-item-action>
          <i class="far fa-clipboard-check"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Autorização de Fichas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessOperations()">
        <v-list-item-action>
          <i class="far fa-hand-holding-usd"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Operações</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessUsers()">
        <v-list-item-action>
          <i class="far fa-user"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Usuários</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group no-action>
        <template v-slot:prependIcon>
          <i class="far fa-cog"></i>
        </template>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Configurações</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item @click="accessOperationNatures()">
          <v-list-item-content>
            <v-list-item-title>Naturezas de Operação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessCurrencies()">
          <v-list-item-content>
            <v-list-item-title>Moedas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="acessPaymentMethods()">
          <v-list-item-content>
            <v-list-item-title>Métodos de Pagamento</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action>
        <template v-slot:prependIcon>
          <i class="far fa-file-alt"></i>
        </template>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Relatórios</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item @click="accessOpenOperation()">
          <v-list-item-content>
            <v-list-item-title>Operações em Aberto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessExchangeOperationsReports()">
          <v-list-item-content>
            <v-list-item-title>Operações de Câmbio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessRemittanceOperationsReports()">
          <v-list-item-content>
            <v-list-item-title>Operações de Remessa</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

    </v-list-item-group>
  </v-list>
</template>
<script>
import mixinAuthorization from "../../mixin/mixin-authorization";
import { ACCESS_ROUTES } from "@/constants/general-constants";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "MenuStatic",
  mixins: [mixinAuthorization],
  data() {
    return {
      acess: ACCESS_ROUTES,
    };
  },
  methods: {
    accessUsers() {
      mixpanel.track("click", {
        button_name: "menu_users",
      });
      this.$router
        .push({
          name: "UsersControl",
        })
        .catch(() => { });
    },
    accessCustomers() {
      mixpanel.track("click", {
        button_name: "menu_customers",
      });
      this.$router
        .push({
          name: "CustomersControl",
        })
        .catch(() => { });
    },
    accessBanks() {
      mixpanel.track("click", {
        button_name: "menu_banks",
      });
      this.$router
        .push({
          name: "ExchangeBankControl",
        })
        .catch(() => { });
    },

    accessCustomerAuthorizations() {
      mixpanel.track("click", {
        button_name: "menu_customer_authorizations",
      });
      this.$router
        .push({
          name: "CustomersAuthorizationControl",
        })
        .catch(() => { });
    },

    accessCorrespondents() {
      mixpanel.track("click", {
        button_name: "menu_correspondents",
      });
      this.$router
        .push({
          name: "CorrespondentsControl",
        })
        .catch(() => { });
    },

    accessOperationNatures() {
      mixpanel.track("click", {
        button_name: "menu_configurations_operation_nature",
      });
      this.$router
        .push({
          name: "OperationNaturesControl",
        })
        .catch(() => { });
    },

    accessCurrencies() {
      mixpanel.track("click", {
        button_name: "menu_configurations_currencies",
      });
      this.$router
        .push({
          name: "CurrenciesControl",
        })
        .catch(() => { });
    },

    acessPaymentMethods() {
      mixpanel.track("click", {
        button_name: "payment_methods",
      });
      this.$router
        .push({
          name: "PaymentMethodsControl",
        })
        .catch(() => { });
    },

    accessOperations() {
      mixpanel.track("click", {
        button_name: "menu_operations",
      });
      this.$router
        .push({
          name: "FormOperation",
        })
        .catch(() => { });
    },

    accessOpenOperation() {
      mixpanel.track("click", {
        button_name: "menu_open_operations",
      });
      this.$router
        .push({
          name: "OpenOperationsReport",
        })
        .catch(() => { });
    },

    accessExchangeOperationsReports() {
      mixpanel.track("click", {
        button_name: "menu_exchange_operations_reports",
      });
      this.$router
        .push({
          name: "ExchangeOperationReport",
        })
        .catch(() => { });
    },

    accessRemittanceOperationsReports() {
      mixpanel.track("click", {
        button_name: "menu_remittance_operations_reports",
      });
      this.$router
        .push({
          name: "RemittanceOperationReport",
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-item--active.v-list-item--active.v-list-item.v-list-item--link,
.v-list .v-list-item--active .v-icon {
  color: $primary_color;
}
</style>
