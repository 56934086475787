<template>
  <v-app>
    <Menu 
      v-if="this.$router.currentRoute.name != 'Login' &&
            this.$router.currentRoute.name != 'FormEditCustomerExternalAcess' &&
            this.$router.currentRoute.name != 'FormEditCorrespondentExternalAcess'
    "></Menu>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="type"
      elevation="0"
      right
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-main >
      <router-view />
      <div id="versao">{{ version }}</div>
    </v-main>
  </v-app>
</template>

<script>
// Constants
import { VERSION } from "./constants/configurations-constants";

// Components
import Menu from "@/components/menu/Menu";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Storage
import utilsStorage from "@/utils/storage";
export default {
  name: "App",
  mixins: [mixinMessage],
  components: {
    Menu,
  },

  data () {
    return {
      version: VERSION,
    };
  },
  mounted() { 
    this.verifyTheme()
  },
  methods: { 
    verifyTheme(){
      this.$vuetify.theme.dark = utilsStorage.getThemeSettings()
    },
  },
};
</script>
<style lang="scss" scoped>
#versao {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
