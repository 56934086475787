<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="">
        <h1>Documentos Operacionais do Cliente</h1>
        <v-row class="pt-3">
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>
          <template v-for="(document, i) in operation.customerOperationalDocuments">
            <v-col class="py-0" cols="12" md="3" :key="'type' + i">
              <span class="document-description"> {{ document.operationalDocumentType.description }} </span>
            </v-col>
            <v-col class="py-0 text-right" cols="12" md="3" :key="'actions' + i">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon text @click="downloadDocument(document)" v-bind="attrs" v-on="on">
                     <i class="far fa-file-download"></i>
                  </v-btn>
                </template>
                <span>Baixar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon text @click="removeDocument(i)" v-bind="attrs" v-on="on">
                    <i class="far fa-trash"></i>
                  </v-btn>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </v-col>
          </template>
          <v-col md="12">
            <v-btn class="btn-secondary float-right" @click="openAddDocumentModal" text><i class="far fa-search" ></i>
              Buscar no Cliente</v-btn>
          </v-col>
        </v-row>
    </v-col>

    <!-- Add operational document Modal -->
    <v-dialog persistent eager v-model="addDocumentModal" max-width="500px">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Documento Operacional</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col cols="12" md="12">
              <v-form id="add-currency" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <v-autocomplete label="Documentos" :items="documents" item-text="operationalDocumentType.description"
                      item-value="id" v-model="selectedDocument" return-object clearable dense outlined
                      v-disabled-icon-focus>
                    </v-autocomplete>
                  </v-col>

                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn text class="btn-primary mr-2" @click="insertDocument">
              Salvar
            </v-btn>
            <v-btn text class="btn-secondary" @click="addDocumentModal = false">
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// APIs
import apiCustomerOperationalDocuments from "@/api/customer/customer-operational-documents-api";
import apiCustomers from "@/api/customer/customer-api";

// Models
import CustomerModel from "@/model/customer-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "CustomerOperationalDocuments",
  mixins: [mixinMessage],
  components: {},
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  data() {
    return {
      operation: this.operationProp,
      documents: [],
      addDocumentModal: false,
      selectedDocument: undefined,
    };
  },
  mounted() { },
  watch: {
    "operation.customer"() {
      if (this.operation.customer == undefined) {
        this.customer = new CustomerModel();
        return;
      }

      this.getCustomer();
    },
  },
  methods: {
    insertDocument() {
      this.operation.customerOperationalDocuments.push(this.selectedDocument);
      this.addDocumentModal = false;
    },
    removeDocument(index) {
      this.operation.customerOperationalDocuments.splice(index, 1);
    },
    getCustomer() {
      apiCustomers
        .findId(this.operation.customer.id)
        .then((response) => {
          this.documents = new CustomerModel(response.data).operationalDocuments;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    openAddDocumentModal() {
      this.selectedDocument = undefined;
      this.addDocumentModal = true;
    },
    downloadDocument(operationDocument) {
      apiCustomerOperationalDocuments
        .download(operationDocument.id)
        .then((response) => {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");

          link.href = URL.createObjectURL(blob);
          link.setAttribute(
            "download",
            `${operationDocument.operationalDocumentType.description}`
          );
          link.click();

          URL.revokeObjectURL(link.href);

          this.sendMessage("Baixando anexo", "success");
        })
        .catch(() => {
          this.sendMessage("Falha ao baixar o anexo!", "error");
        });
    },
  },
};
</script>

<style scoped>
.document-description {
  font-size: 1.5em;
  font-weight: 300;
}
</style>