'use strict';

function calculateOperationRemittance(operation) {
  if (operation.exchangeBuy) {
    _calculateOperationRemittanceBuy(operation);
  } else {
    _calculateOperationRemittanceSell(operation);
  }
}

function _calculateOperationRemittanceBuy(operation) {
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(2);
  operation.irValue = ((operation.customerRate * operation.amount * operation.ir / 100) || 0).toFixed(2);
  operation.exchangeContractCost = operation.exchangeContractCost || operation.bank.exchangeContractCost || 0;
  operation.totalValue = ((parseFloat(operation.customerRate) * parseFloat(operation.amount) - parseFloat(operation.irValue) - parseFloat(operation.iofValue) - parseFloat(operation.exchangeContractCost)) || 0).toFixed(2);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
}

function _calculateOperationRemittanceSell(operation) {
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(2);
  operation.irValue = ((operation.customerRate * operation.amount * operation.ir / 100) || 0).toFixed(2);
  operation.exchangeContractCost = operation.exchangeContractCost || operation.bank.exchangeContractCost || 0;
  operation.totalValue = ((parseFloat(operation.customerRate) * parseFloat(operation.amount) + parseFloat(operation.irValue) + parseFloat(operation.iofValue) + parseFloat(operation.exchangeContractCost)) || 0).toFixed(2);
  operation.vet = ((operation.totalValue / operation.amount || 0)).toFixed(4);
}

function setBaseValuesOperationRemittance(operation) {
  if (operation.exchangeBuy) {
    _setBaseValuesOperationRemittanceBuy(operation)
  } else {
    _setBaseValuesOperationRemittanceSell(operation)
  }
}

function _setBaseValuesOperationRemittanceBuy(operation) {
  let spreadAgent = operation.agent.spreads.find(i => i.currency.id == operation.currency.id);
  let spreadCustomer = operation.customer.spreads.find(i => i.currency.id == operation.currency.id);

  operation.iof = operation.nature.iofBuy || 0;
  operation.ir = operation.nature.irBuy || 0;
  operation.spread = operation.spread || spreadCustomer?.recommendedSpreadBuy || spreadAgent?.recommendedSpreadBuy || operation.currency.recommendedSpreadBuy;
  operation.spreadMin = operation.spreadMin || spreadCustomer?.minimumSpreadBuy || spreadAgent?.minimumSpreadBuy || operation.currency.minimumSpreadBuy;
  operation.spreadMax = operation.spreadMax || spreadCustomer?.maximumSpreadBuy || spreadAgent?.maximumSpreadBuy || operation.currency.maximumSpreadBuy;
  operation.customerRate = ((operation.currencyQuotation * (1 - operation.spread / 100)) || 0).toFixed(6);
  operation.customerRateMin = ((operation.currencyQuotation * (1 - operation.spreadMax / 100)) || 0).toFixed(6);
  operation.customerRateMax = ((operation.currencyQuotation * (1 - operation.spreadMin / 100)) || 0).toFixed(6);
}

function _setBaseValuesOperationRemittanceSell(operation) {
  let spreadAgent = operation.agent.spreads.find(i => i.currency.id == operation.currency.id);
  let spreadCustomer = operation.customer.spreads.find(i => i.currency.id == operation.currency.id);

  operation.iof = operation.nature.iof || 0;
  operation.ir = operation.nature.ir || 0;
  operation.spread = operation.spread || spreadCustomer?.recommendedSpreadSell || spreadAgent?.recommendedSpread || operation.currency.recommendedSpreadSell;
  operation.spreadMin = operation.spreadMin || spreadCustomer?.minimumSpreadSell || spreadAgent?.minimumSpread || operation.currency.minimumSpreadSell;
  operation.spreadMax = operation.spreadMax || spreadCustomer?.maximumSpreadSell || spreadAgent?.maximumSpread || operation.currency.maximumSpreadSell;
  operation.customerRate = ((operation.currencyQuotation * (1 + operation.spread / 100)) || 0).toFixed(6);
  operation.customerRateMin = ((operation.currencyQuotation * (1 + operation.spreadMin / 100)) || 0).toFixed(6);
  operation.customerRateMax = ((operation.currencyQuotation * (1 + operation.spreadMax / 100)) || 0).toFixed(6);
}

function resetSpreads(operation) {
  operation.spreadMin = undefined;
  operation.spreadMax = undefined;
  operation.spread = undefined;
}

function getSpreadFromCustomerRateRemittance(operation) {
  if (operation.exchangeBuy) {
    _getSpreadFromCustomerRateRemittanceBuy(operation);
  } else {
    _getSpreadFromCustomerRateRemittanceSell(operation);
  }
}

function _getSpreadFromCustomerRateRemittanceBuy(operation) {
  operation.spread = ((- (operation.customerRate / operation.currencyQuotation) + 1) * 100).toFixed(2);
}

function _getSpreadFromCustomerRateRemittanceSell(operation) {
  operation.spread = (((operation.customerRate / operation.currencyQuotation) - 1) * 100).toFixed(2);
}

function setBaseValuesOperationExchange(operation) {
  if (operation.exchangeBuy) {
    _setBaseValuesOperationExchangeBuy(operation);
  } else {
    _setBaseValuesOperationExchangeSell(operation);
  }
}

function _setBaseValuesOperationExchangeBuy(operation) {
  let storeSpread = operation.store.exchangeSpreads.find(i => i.currency.id == operation.currency.id);

  operation.iof = operation.nature.iofBuy || 0;

  operation.spread = operation.spread || storeSpread?.recommendedSpreadBuy;
  operation.spreadMin = operation.spreadMin || storeSpread?.minimumSpreadBuy;
  operation.spreadMax = operation.spreadMax || storeSpread?.maximumSpreadBuy;

  operation.customerRateNoIOF = ((operation.currencyQuotation * (1 - operation.spread / 100)) || 0).toFixed(6);
  operation.customerRateNoIOFMin = ((operation.currencyQuotation * (1 - operation.spreadMax / 100)) || 0).toFixed(6);
  operation.customerRateNoIOFMax = ((operation.currencyQuotation * (1 - operation.spreadMin / 100)) || 0).toFixed(6);

  operation.customerRate = ((operation.customerRateNoIOF * (1 - operation.iof / 100)) || 0).toFixed(6);
  operation.customerRateMin = ((operation.customerRateNoIOFMin * (1 - operation.iof / 100)) || 0).toFixed(6);
  operation.customerRateMax = ((operation.customerRateNoIOFMax * (1 - operation.iof / 100)) || 0).toFixed(6);

}

function _setBaseValuesOperationExchangeSell(operation) {
  let storeSpread = operation.store.exchangeSpreads.find(i => i.currency.id == operation.currency.id);

  operation.iof = operation.nature.iof || 0;

  operation.spread = operation.spread || storeSpread?.recommendedSpread;
  operation.spreadMin = operation.spreadMin || storeSpread?.minimumSpread;
  operation.spreadMax = operation.spreadMax || storeSpread?.maximumSpread;

  operation.customerRateNoIOF = ((operation.currencyQuotation * (1 + operation.spread / 100)) || 0).toFixed(6);
  operation.customerRateNoIOFMin = ((operation.currencyQuotation * (1 + operation.spreadMin / 100)) || 0).toFixed(6);
  operation.customerRateNoIOFMax = ((operation.currencyQuotation * (1 + operation.spreadMax / 100)) || 0).toFixed(6);


  operation.customerRate = ((operation.customerRateNoIOF * (1 + operation.iof / 100)) || 0).toFixed(6);
  operation.customerRateMin = ((operation.customerRateNoIOFMin * (1 + operation.iof / 100)) || 0).toFixed(6);
  operation.customerRateMax = ((operation.customerRateNoIOFMax * (1 + operation.iof / 100)) || 0).toFixed(6);
}

function calculateOperationExchange(operation) {
  if (operation.exchangeBuy) {
    _calculateOperationExchangeBuy(operation);
  } else {
    _calculateOperationExchangeSell(operation);
  }
}

function _calculateOperationExchangeBuy(operation) {
  operation.totalValue = ((operation.customerRate * operation.amount - parseFloat(operation.deliveryCost)) || 0).toFixed(2);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(2);
}

function _calculateOperationExchangeSell(operation) {
  operation.totalValue = ((operation.customerRate * operation.amount + parseFloat(operation.deliveryCost)) || 0).toFixed(2);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(2);
}

function getSpreadFromCustomerRateExchange(operation) {
  if (operation.exchangeBuy) {
    _getSpreadFromCustomerRateExchangeBuy(operation);
  } else {
    _getSpreadFromCustomerRateExchangeSell(operation);
  }
}

function _getSpreadFromCustomerRateExchangeBuy(operation) {
  operation.customerRateNoIOF = ((operation.customerRate / (1 - operation.iof / 100)) || 0).toFixed(6);
  operation.spread = ((- (operation.customerRateNoIOF / operation.currencyQuotation) + 1) * 100).toFixed(2);
}

function _getSpreadFromCustomerRateExchangeSell(operation) {
  operation.customerRateNoIOF = ((operation.customerRate / (1 + operation.iof / 100)) || 0).toFixed(6);
  operation.spread = (((operation.customerRateNoIOF / operation.currencyQuotation) - 1) * 100).toFixed(2);
}

export default {
  calculateOperationRemittance,
  setBaseValuesOperationRemittance,
  resetSpreads,
  getSpreadFromCustomerRateRemittance,
  setBaseValuesOperationExchange,
  calculateOperationExchange,
  getSpreadFromCustomerRateExchange,
};