<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Bancos / Corretoras</h1>
        <v-divider class="py-3 mt-3"></v-divider>
        <v-row>
          <v-col cols="12" md="10">
            <v-btn class="btn-primary ml-0" text @click="openAddExchangeBankModal">
              <i class="far fa-plus" ></i>
              Adicionar
            </v-btn>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
              hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-card class="card-cambio">
              <v-data-table :headers="columns" :items="customer.exchangeBanks" :search="search" sort-by="id" sort-desc
                :items-per-page="10">
                <template v-slot:[`item.operationNatures`]="{ item }">
                  {{ item.operationNatures.map(i => i.name).join(", ") }}
                </template>
                <template v-slot:[`item.limit.remaining`]="{ item }">
                  <template v-if="item.bank.useUsdLimit">
                    {{ item.limit.remaining | formatUSD }}
                  </template>
                  <template v-else>
                    {{ item.limit.remaining | formatBRL }}
                  </template>
                </template>
                <template v-slot:[`item.limit.expireAt`]="{ item }">
                  {{ item.limit.expireAt | date }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon text @click="editExchangeBankModal(item)" v-bind="attrs" v-on="on">
                        <i class="far fa-pencil" ></i>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon text @click="openAddLimitModal(item)" v-bind="attrs" v-on="on">
                        <i class="far fa-hand-holding-usd"></i>
                      </v-btn>
                    </template>
                    <span>Adicionar Limite</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Exchange Bank Modal -->
    <v-dialog persistent scrollable eager min-width="40vw" max-width="100vh" v-model="addExchangeBankModal">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Banco / Corretora</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form id="add-exchange-bank" ref="form">
                <v-row>
                  <v-col md="12">
                    <v-autocomplete label="Tipo de operação" :items="filteredExchangeTypes" item-text="description"
                      item-value="id" v-model="exchangeBank.exchangeType" return-object clearable dense outlined
                      v-disabled-icon-focus>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="12" v-if="exchangeBank.exchangeType.id == 1">
                    <v-autocomplete label="Banco" :items="getRemittanceBanks" item-text="fantasyName" item-value="id"
                      v-model="exchangeBank.bank" clearable dense outlined v-disabled-icon-focus return-object>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="12" v-else>
                    <v-autocomplete label="Banco" :items="getTourismBanks" item-text="fantasyName" item-value="id"
                      v-model="exchangeBank.bank" clearable dense outlined v-disabled-icon-focus return-object>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="12">
                    <v-autocomplete label="Natureza da Operação" :items="operationNatureList" item-text="name"
                      item-value="id" v-model="exchangeBank.operationNatures" clearable dense outlined multiple
                      v-disabled-icon-focus return-object>
                    </v-autocomplete>
                  </v-col>

                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn v-if="!update" text class="btn-primary mr-2" @click="addExchangeBank">
              Salvar
            </v-btn>
            <v-btn v-else-if="update" text class="btn-primary mr-2" @click="updateExchangeBank">
              Salvar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="addExchangeBankModal = false">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Limit Modal -->
    <v-dialog persistent scrollable eager min-width="40vw" max-width="100vh" v-model="addLimitModal">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Limite</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form id="add-limit-modal" ref="form">
                <v-row>
                  <v-col md="12">
                    <MoneyField label="Valor" prefix=" " v-model="limit.value" />
                  </v-col>
                  <v-col md="12">
                    <Datepicker v-model="limit.expireAt" label="Data de Validade" v-disabled-icon-focus />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn text class="btn-primary mr-2" @click="addLimit">
              Salvar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="addLimitModal = false">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiOperationNature from "@/api/configuration/operation-nature-api";
import apiExchangeBankType from "@/api/exchangeBank/exchange-bank-type-api";
import apiCustomer from "@/api/customer/customer-api";

// Components
import MoneyField from "@/components/comum/MoneyField";
import Datepicker from "@/components/comum/Datepicker.vue";

// Models
import CustomerExchangeBankModel from "@/model/customer-exchange-bank-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import OperationNatureModel from "@/model/operation-nature-model";
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";
import CustomerLimitModel from "@/model/customer-limit-model";

// Utils
import dateUtils from "@/utils/date";
import moneyUtils from "@/utils/money";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "CustomerExchangeBanks",
  mixins: [mixinMessage],
  components: {
    MoneyField,
    Datepicker,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatBRL(value) {
      return moneyUtils.formatCurrency('BRL', value);
    },
    formatUSD(value) {
      return moneyUtils.formatCurrency('USD', value);
    },
  },
  data() {
    return {
      customer: this.customerProp,
      search: "",
      banks: [],
      operationNatureList: [],
      exchangeTypes: [],
      columns: [
        { text: "Tipo", value: "exchangeType.description", align: "left" },
        { text: "Banco Vinculado", value: "bank.fantasyName", align: "left" },
        { text: "Naturezas Aprovadas", value: "operationNatures", align: "left", },
        { text: "Limite Restante", value: "limit.remaining", align: "left", },
        { text: "Validade do Limite", value: "limit.expireAt", align: "left", },
        { text: "", value: "actions", align: "right" },
      ],
      exchangeBank: new CustomerExchangeBankModel(),
      addExchangeBankModal: false,
      update: false,
      addLimitModal: false,
      limit: new CustomerLimitModel(),
    };
  },
  mounted() {
    this.getBankList();
    this.getOperationNatureList();
    this.getExchangeTypes();
  },
  computed: {
    getRemittanceBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 2);
    },

    getTourismBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 1);
    },

    filteredExchangeTypes() {
      let filtered = this.exchangeTypes.filter(i => i.id != 3);
      return filtered;
    }
  },
  methods: {
    openAddExchangeBankModal() {
      this.update = false;
      this.exchangeBank = new CustomerExchangeBankModel();
      this.addExchangeBankModal = true;
    },

    editExchangeBankModal(exchangeBank) {
      this.update = true;
      this.exchangeBank = Object.assign({}, exchangeBank);
      this.addExchangeBankModal = true;
    },

    addExchangeBank() {
      this.customer.exchangeBanks.push(new CustomerExchangeBankModel(this.exchangeBank));
      this.addExchangeBankModal = false;
    },

    updateExchangeBank() {
      let index = this.customer.exchangeBanks.findIndex((b) =>
        (b.bank.id == this.exchangeBank.bank.id && b.exchangeType.id == this.exchangeBank.exchangeType.id)
      );

      this.customer.exchangeBanks.splice(
        index,
        1,
        new CustomerExchangeBankModel(this.exchangeBank)
      );
      this.addExchangeBankModal = false;
    },

    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    getOperationNatureList() {
      apiOperationNature
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getExchangeTypes() {
      apiExchangeBankType
        .findAll()
        .then((response) => {
          this.exchangeTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddLimitModal(exchangeBank) {
      this.exchangeBank = Object.assign({}, exchangeBank.bank);

      this.limit = new CustomerLimitModel();
      let expire = new Date();
      expire.setFullYear(expire.getFullYear() + 1);
      this.limit.expireAt = expire.toISOString();

      this.addLimitModal = true;
    },

    addLimit() {
      this.limit.remaining = this.limit.value;

      apiCustomer
        .addLimit(this.customer.id, this.exchangeBank.id, this.limit)
        .then((response) => {
          let index = this.customer.exchangeBanks.findIndex((b) => (b.bank.id == this.exchangeBank.id));

          this.customer.exchangeBanks[index].limit = new CustomerLimitModel(response.data);
          this.addLimitModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    }
  }
};
</script>

<style></style>
