<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="12">
      <div class="box">
        <h1 class="titulo">Métodos de Pagamento</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col cols="12" md="9">
            <v-btn class="btn-primary ml-0" text @click="openAddMethodModal">
              <i class="far fa-plus" ></i> Adicionar
            </v-btn>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
              hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-card class="card-cambio">
              <v-data-table :headers="columns" :items="methods" :search="search" sort-by="id" sort-asc
                :items-per-page="10" :custom-filter="caseInsensitiveAccentsInsensitive">
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon text color="primary" @click="editMethodModal(item)" v-bind="attrs" v-on="on">
                        <i class="far fa-pencil" ></i>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Payment Method -->
    <v-dialog persistent eager v-model="addMethodModal" max-width="500px">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Métodos de Pagamento</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col cols="12" md="12">
              <v-form id="add-method" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <v-text-field outlined dense label="Nome" v-model="method.name"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn v-if="!update" text class="btn-primary mr-2" @click="addMethod">
              Salvar
            </v-btn>
            <v-btn v-else-if="update" text class="btn-primary mr-2" @click="updatemethod">
              Salvar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="addMethodModal = false">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiPaymentMethod from "@/api/configuration/payment-method-api";

// Models
import PaymentMethodModel from "@/model/payment-method-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

export default {
  name: "PaymentMethodsControl",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: {},
  data() {
    return {
      search: "",
      methods: [],
      columns: [
        { text: "Código", value: "id", align: "left", sortable: true, width: "100px" },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      method: new PaymentMethodModel(),
      addMethodModal: false,
      update: false,
    };
  },
  watch: {},
  mounted() {
    this.getMethodList();
  },
  computed: {},
  methods: {
    getMethodList() {
      apiPaymentMethod
        .findAll()
        .then((response) => {
          this.methods = response.data.map((n) => new PaymentMethodModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddMethodModal() {
      this.update = false;
      this.method = new PaymentMethodModel();
      this.addMethodModal = true;
    },

    editMethodModal(method) {
      this.update = true;
      this.method = new PaymentMethodModel(method);
      this.addMethodModal = true;
    },

    addMethod() {
      apiPaymentMethod
        .add(this.method)
        .then((response) => {
          this.methods.push(new PaymentMethodModel(response.data));
          this.addMethodModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updatemethod() {
      apiPaymentMethod
        .update(this.method)
        .then((response) => {
          let index = this.methods.findIndex((s) => s.id == this.method.id);
          this.methods.splice(index, 1, new PaymentMethodModel(response.data));
          this.addMethodModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
