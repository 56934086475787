<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="button" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-thead" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tbody" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tfoot" min-width="100px"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" md="12">
        <h1 class="titulo">Controle de usuários</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="9">
        <v-btn class="btn-primary ml-0" text @click="addUserDialog">
          <i class="far fa-plus" ></i>
          Adicionar</v-btn>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
          hide-details></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="card-cambio">
          <v-data-table :headers="columns" :items="users" :search="search" sort-by="id" sort-desc :items-per-page="10">
            <template v-slot:[`item.profile.id`]="{ item }">
              {{ item.profile.id | translateProfileDescription }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | date }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span v-if="!item.dateInactivation"><v-chip small label color="green lighten-1">Ativo</v-chip></span>
              <span v-else><v-chip small label color="red darken-1">Inativo</v-chip></span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <td v-if="!item.dateInactivation" class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon text color="primary" @click="editUser(item)" v-bind="attrs" v-on="on">
                      <i class="far fa-pencil" ></i>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon text color="primary" @click="deleteUserConfirmationDialog(item)" v-bind="attrs" v-on="on">
                      <i class="far fa-trash" ></i>
                    </v-btn>
                  </template>
                  <span>Inativar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent scrollable min-height="800px" max-width="400px">
      <v-card flat>
        <v-card-title>
          <h1 class="titulo-modal">
            {{ !updateMode ? "Adicionar " : "Editar " }} usuário
          </h1>
        </v-card-title>

        <v-card-text>
          <v-divider></v-divider>
          <v-container>
            <form autocomplete="off">
              <v-row class="mt-2">
                <v-col cols="12" md="12">
                  <v-text-field v-model="user.name" label="Nome" autocomplete="off" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field v-model="user.email" label="E-mail" autocomplete="off" type="email" dense :rules="[
                    (v) => !!v || 'E-mail é obrigatório',
                    (v) =>
                      !v ||
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'Informe um e-mail válido',
                  ]">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-autocomplete :items="profiles" label="Perfil" v-model="user.profile" item-text="description"
                    item-value="id" return-object clearable autocomplete="off" dense></v-autocomplete>
                </v-col>
                <v-col v-if="!updateMode" cols="12" md="12">
                  <v-text-field label="Senha" v-model="user.password" :append-icon="passwordVisible ? 'visibility_off' : 'visibility'
                    " @click:append="() => (passwordVisible = !passwordVisible)"
                    :type="passwordVisible ? 'password' : 'text'" :rules="[
                      (v) => {
                        return !v
                          ? true
                          : (v && v.trim().length >= 6) ||
                          'Senha deve ter mínimo de 6 caracteres';
                      },
                    ]" dense></v-text-field>
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn text class="btn-primary mr-2" :disabled="!this.formIsValid() || disableButtons" @click="save">
              Salvar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="cancel"> Cancelar </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-window v-model="openWindowConfirmation" :callbackPrimary="callbackConfirmDeletion"
      :message="deleteMessage" @onChange="clearInputs"></confirmation-window>
  </div>
</template>
<script>
// Apis
import apiUser from "@/api/user/user-api";

// Models
import UserModel from "@/model/user-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Components
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";

// Utils
import dateUtils from "@/utils/date";

// Constants
import { PROFILES } from "@/constants/general-constants";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "UsersControl",
  mixins: [mixinMessage],
  components: {
    ConfirmationWindow,
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    dateHour(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
    translateProfileDescription(value) {
      if (isNaN(value)) {
        return value;
      }

      return PROFILES.find((p) => p.id == value).description;
    },
  },
  data() {
    return {
      passwordVisible: "password",
      dialog: false,
      loading: true,
      updateMode: false,
      disableButtons: false,
      openWindowConfirmation: false,
      deleteMessage: "",
      search: "",
      columns: [
        { text: "Código", value: "id", sortable: false },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "E-mail", value: "email", align: "left", sortable: false },
        { text: "Perfil", value: "profile.id", sortable: false },
        { text: "Data de cadastro", value: "createdAt", align: "center" },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        { text: "", value: "actions", sortable: false },
      ],
      user: new UserModel(),
      users: [],
      callbackConfirmDeletion: () => { },
      profiles: PROFILES,
    };
  },
  mounted() {
    mixpanel.track("page_view", { name_of_page_viewed: "users_control" });

    this.getUserList();
  },
  computed: {
    minimumDate() {
      return new Date().toISOString();
    },
  },
  methods: {
    addUserDialog() {
      this.user = new UserModel();
      this.dialog = true;
      mixpanel.track("click", {
        name_of_page_viewed: "users_control",
        button_name: "btn_new_user",
      });
    },

    getUserList() {
      apiUser
        .findAll()
        .then((response) => {
          this.users = response.data.map((u) => new UserModel(u));

          this.clearInputs();
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    save() {
      !this.updateMode ? this.addUser() : this.updateUser();
    },

    editUser(user) {
      mixpanel.track("click", {
        name_of_page_viewed: "users_control",
        button_name: "btn_icon_edit_user",
      });
      this.updateMode = true;
      this.user = new UserModel(user);
      this.dialog = true;
    },

    cancel() {
      this.dialog = false;
      this.user = new UserModel();
      this.updateMode = false;
    },

    clearInputs() {
      this.user = new UserModel();
    },

    addUser() {
      this.updateMode = false;
      this.disableButtons = true;
      let user = new UserModel(this.user);
      user.profileId = this.user.profile.id;

      apiUser
        .add(user)
        .then((response) => {
          this.sendMessage("Usuário cadastrado com sucesso!", "success");
          this.users.push(new UserModel(response.data));
          this.clearInputs();
          this.dialog = false;
          this.disableButtons = false;
        })
        .catch((erro) => {
          this.sendMessage(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
          this.disableButtons = false;
        });
    },

    updateUser() {
      this.updateMode = true;
      this.disableButtons = true;
      let user = new UserModel(this.user);
      user.profileId = this.user.profile.id;

      apiUser
        .update(user)
        .then((response) => {
          this.sendMessage(
            `${this.user.email} foi atualizado com sucesso!`,
            "success"
          );
          this.updateListUser(new UserModel(response.data));
          this.clearInputs();
          this.dialog = false;
          this.disableButtons = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.disableButtons = false;
        });
    },

    deleteUserConfirmationDialog(user) {
      mixpanel.track("click", {
        name_of_page_viewed: "users_control",
        button_name: "btn_icon_delete_user",
      });
      this.user = new UserModel(user);
      this.deleteMessage = `Deseja realmente inativar o usuário &nbsp; <strong>${user.email}</strong>?`;
      this.callbackConfirmDeletion = this.deleteUser;
      this.openWindowConfirmation = true;
    },

    deleteUser() {
      apiUser
        .inactive(this.user)
        .then((response) => {
          let user = new UserModel(response.data);

          this.sendMessage(
            `O ${this.user.email} foi inativado com sucesso!`,
            "success"
          );

          this.updateListUser(user, false);
        })
        .catch((error) =>
          this.sendMessage(error.response.data.mensagem, "error")
        )
        .finally((this.openWindowConfirmation = false));
    },

    updateListUser(user, deleteFromList) {
      var index = this.users
        .map((u) => parseInt(u.id))
        .indexOf(parseInt(user.id));
      !deleteFromList
        ? this.users.splice(index, 1, user)
        : this.users.splice(index, 1);
    },

    formIsValid() {
      if (this.updateMode && !this.user.password) {
        return this.user.validModelUpdate();
      }
      return this.user.validModel();
    },
  },
};
</script>

<style scoped></style>
